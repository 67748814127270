import { render, staticRenderFns } from "./v1_mobile.vue?vue&type=template&id=6ddd4e09&scoped=true&style=background-color%3A%20%23f4f5f7%3B%20&"
import script from "./v1_mobile.vue?vue&type=script&lang=js&"
export * from "./v1_mobile.vue?vue&type=script&lang=js&"
import style0 from "./v1_mobile.vue?vue&type=style&index=0&id=6ddd4e09&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ddd4e09",
  null
  
)

export default component.exports