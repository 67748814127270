<template style="background-color: #f4f5f7; ">
  <div class="settings" :style="{ ' width: 100%; background-color: #f4f5f7;  min-height': windowHeight - 110 + 'px' }">

    <div style="z-index: 99;  background-color: #fff;" class="appBar android">
      <div style="flex:3; background-color: #fff; width: 100%; text-align: left; display: flex; height: 50px;">
        <router-link class="appBarLogoText" :to="prefixPath" style="text-align: left;"><img class="app-logo" src="https://gcdn.bionluk.com/site/general/bionluk_logo_gk.png"></router-link>
      </div>
    </div>

    <div :class="'underAppBar ' + deviceType"></div>


    <ul class="nav-list" style=" width: auto; margin: 0!important;padding: 10px 0 0 0; padding-bottom: 80px;">

      <div class="nav-menutext" style=" margin-top:15px;padding-left: 30px;text-transform: uppercase;">MENÜ</div>
      <li>
        <router-link :to="'/' + user.username"  active-class="active">
          <span style="position: absolute; margin-top: 2px; margin-left: 35px;">Benim Profilim</span>
          <img style="height: 20px;" src="https://gcdn.bionluk.com/site/icon/ic_account_box_gray.svg">
        </router-link>
      </li>

      <li>
        <router-link :to="feedNotifications.path" active-class="active">
          <span style="position: absolute; margin-left: 35px;">Bildirimler</span>
          <img style="height: 20px;" src="https://gcdn.bionluk.com/site/icon/aws/icon_adver.svg">
          <span v-if="user.unreadNotificationCount > 0" class="button-badge" style=" margin-left: 80px;"><span style="line-height: 16px; text-align: center;">{{ user.unreadNotificationCount }}</span></span>
        </router-link>
      </li>




      <div class="nav-menutext" style=" margin-top:15px;padding-left: 30px;">AYARLAR</div>
      <li>
        <router-link :to="'/settings'" active-class="active" exact>
          <span style="position: absolute; margin-left: 34px;">Genel Ayarlar</span>
          <img :src="$root.CDN_SITE + 'icon/ic_settings_gray.svg'">
        </router-link>
      </li>
      <li>
        <router-link :to="'/settings/notifications'" active-class="active">
          <span style="position: absolute; margin-left: 34px;">Bildirim Ayarları</span>
          <img style="height: 20px; margin-left: 2px;" :src="$root.CDN_SITE + 'icon/ic_zil_gray.svg'">
        </router-link>
      </li>

      <div class="nav-menutext" style=" margin-top:15px;padding-left: 30px;">LİNKLER</div>


      <li>
        <router-link :to="'/blog'" active-class="active">
          <span style="position: absolute; margin-left: 34px;">Blog</span>
          <img :src="$root.CDN_SITE + 'icon/ic_format_align_center_gray.svg'">
        </router-link>
      </li>


      <li>
        <router-link :to="'/tickets'" active-class="active">
          <span style="position: absolute; margin-left: 35px;">Destek</span>
          <img :src="$root.CDN_SITE + 'icon/ic_live_help_gray.svg'">
        </router-link>
      </li>


      <li @click="logout()">
        <router-link to="">
          <span style="position: absolute; margin-left: 34px;">Çıkış</span>
          <img :src="$root.CDN_SITE + 'icon/ic_exit_to_app_gray.svg'">
        </router-link>
      </li>


    </ul>

  </div>
</template>

<script>

  //import LogRocket from 'logrocket';

  export default {
    name: "src-pages-body-mprofile-v1_mobile",
    data () {
      return {
        feedNotifications: {
          path: "/feed/notifications",
          activeClass: (this.activeLink === "feed_notifications" ? "active" : null)
        }
      };
    },

    methods: {
      logout() {
        this.api.user.logout(this.$Progress).then(({data}) => {
          let result = data;
          if (result.success) {
            //window.Intercom('shutdown');

            //LogRocket.identify();

            amplitude.regenerateDeviceId();
            amplitude.setUserId(null);
            localStorage.removeItem("token");
            localStorage.removeItem("visitor_id");
            window.location.href = "/";
          } else {
            this.$toasted.global.errorToast({description: result.message});
          }
        }).catch(err => {
          this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
        });
      }
    }
  }
</script>

<style scoped lang="scss">
  .chatrow{
    height: 80px; background-color: #ffffff;  display: flex; align-items: center;
    border-top: solid 1px #eaedf2;
  }

  .numberCircle {

    border-radius: 50%;
    width: 32px;

    background-color: #f2b46d;


  }
  .numberCircle span {

    text-align: center;
    line-height: 32px;

    display: block;
    color: #2d3640;
    font-weight: 500;

  }


  .button-badge {
    background: #f2b46d;
    border: 3px solid #ffffff;
    border-radius: 50%;
    color: #ffffff;
    font-size: 11px;
    position: absolute;


    font-weight: 600;
    width: 17px;
    height: 17px;
    text-align: center;
    margin-top: -3px;

  }

  .appBar{
    z-index: 99;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 10px 0px;
  }

  .app-logo{
    height: 28px;
    margin-top:2px;
    margin-left: 15px;
  }

  .nav-list {
    li{
      padding-left: 30px;
    }
  }

</style>
